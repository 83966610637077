import styles from "./Footer.scss";
import { _classes } from "@scripts/utilities/helpers";
import { useAppState, useViewportContext } from "@scripts/providers";
import { Container, Link, Text, Image, Modal } from "@scripts/widgets";
import TwoTreesLogo from "../../../../images/two-trees-log.png";
import { facebook, instagram } from "@scripts/widgets/SVG";
import { useState } from "react";
import APP_CONFIG from "../../../config/appConfig";
import { HubspotContainer } from "@scripts/partials";
import useOnEscape from "../../../hooks/useOnEscape";
import BannerForm from "../../../form/bannerForm";

const cl = _classes(styles);

Footer.propTypes = {
  homepage: PropTypes.bool,
};

export default function Footer({ homepage }) {
  const { byTemplate, toggleAccessible, accessible } = useAppState();
  const { viewport } = useViewportContext();
  const page = byTemplate("footer")[0];
  const privacyPage = byTemplate("privacy_policy")[0];
  const hideDisclaimer = page.checkbox1;
  const [privacyModalOpen, togglePrivacyModal] = useState(false);

  const renderSocialIcons = () => (
    <div className={cl("icons")}>
      <Link path={page.h4} className={cl("icon")}>
        {instagram}
      </Link>
      <Link path={page.h5} className={cl("icon")}>
        {facebook}
      </Link>
    </div>
  );

  useOnEscape(() => {
    if (accessible) {
      toggleAccessible(new Event("click"));
    }
  }, accessible);

  return (
    <div className={cl("root")} id="footer">
      <Container>
        <div className={cl("inner")}>
          <div className={cl("contact")}>
            <div className={cl("left")}>
              <div className={cl("desktop_wrapper")}>
                <Text
                  text={page.h2}
                  variant="h2"
                  component="h3"
                  className={cl(["text", "title"])}
                />
                <Link path={`tel:${page.h3}`}>
                  <Text
                    component="p"
                    text={page.h3}
                    variant="body1"
                    className={cl(["text", "phone"])}
                  />
                </Link>

                {page.blurb1 && (
                  <div
                    className={cl("footer_blurb")}
                    dangerouslySetInnerHTML={{ __html: page.blurb1 }}
                  />
                )}

                {page.blurb6 && (
                  <p
                    className={cl("left-side-blurb")}
                    dangerouslySetInnerHTML={{ __html: page.blurb6 }}
                  />
                )}
              </div>
              {!viewport.is("mobile") && homepage && !hideDisclaimer && (
                <div className={cl("nav_logo_desktop")}>
                  <Link
                    className={cl("logo")}
                    path="https://www.twotreesny.com/"
                  >
                    <Image
                      src={TwoTreesLogo}
                      type="element"
                      alt="two trees logo"
                    />
                  </Link>
                </div>
              )}
            </div>
            <div className={cl("form")}>
              <HubspotContainer
                formId={APP_CONFIG.hubspotFormID}
                className={"hubspot-dark"}
              />
            </div>
          </div>
        </div>
        <div className={cl("nav")}>
          <div className={cl("nav_logo")}>
            {!viewport.is("mobile") && hideDisclaimer && (
              <Link className={cl("logo")} path="https://www.twotreesny.com/">
                <Image src={TwoTreesLogo} type="element" alt="two trees logo" />
              </Link>
            )}
            {viewport.is("mobile") && !hideDisclaimer && (
              <Link className={cl("logo")} path="https://www.twotreesny.com/">
                <Image src={TwoTreesLogo} type="element" alt="two trees logo" />
              </Link>
            )}
            {!viewport.is("mobile") && !homepage && !hideDisclaimer ? (
              <Link className={cl("logo")} path="https://www.twotreesny.com/">
                <Image src={TwoTreesLogo} type="element" alt="two trees logo" />
              </Link>
            ) : (
              page.blurb2 &&
              !viewport.is("mobile") &&
              !hideDisclaimer && (
                <div
                  className={cl("disclaimer")}
                  dangerouslySetInnerHTML={{ __html: page.blurb2 }}
                />
              )
            )}
            {viewport.is("mobile") && renderSocialIcons()}
          </div>

          {!viewport.is("mobile") && renderSocialIcons()}

          <div className={cl("nav_links")}>
            <Link path="/twotrees-team-brooklyn" className={cl("nav_link")}>
              <Text
                component="p"
                text="team"
                className={cl(["text"])}
                variant="body3"
              />
            </Link>
            <Link path="/press-300-kent-refinery" className={cl("nav_link")}>
              <Text
                component="p"
                text="press"
                className={cl(["text"])}
                variant="body3"
              />
            </Link>
            {page && page.h6 && (
              <Link path={page.h6 || "/"} className={cl("nav_link")}>
                <Text
                  component="p"
                  text="Brochure"
                  className={cl(["text"])}
                  variant="body3"
                />
              </Link>
            )}

            {/* <Link path="/downloads" className={cl("nav_link")}>
              <Text
                component="p"
                text="Downloads"
                className={cl(["text"])}
                variant="body3"
              />
            </Link> */}
            <Link
              path="/privacy-policy"
              className={cl("nav_link")}
              onClick={(e) => {
                e.preventDefault();
                togglePrivacyModal(true);
              }}
            >
              <Text
                component="p"
                text="Privacy Policy"
                className={cl(["text"])}
                variant="body3"
              />
            </Link>
            <Link onClick={toggleAccessible} className={cl("nav_link")}>
              <Text
                component="p"
                text={accessible ? "Exit Accessibility Mode" : "Accessibility"}
                className={cl(["text"])}
                variant="body3"
              />
            </Link>
            <div>
              <a
                className={styles.footer__credits}
                href="https://www.onceuponatime.agency/hospitality"
                data-txt="Credits"
                target="_blank"
              >
                <span>Once Upon a Time</span>
              </a>
            </div>
          </div>
          {viewport.is("mobile") && page.blurb2 && !hideDisclaimer && (
            <div
              className={cl("disclaimer")}
              dangerouslySetInnerHTML={{ __html: page.blurb2 }}
            />
          )}
        </div>
      </Container>

      <Modal
        open={privacyModalOpen}
        onClose={() => togglePrivacyModal(false)}
        modalClassname={cl("modal")}
      >
        <div>
          <Text
            text={privacyPage.h1}
            variant="h2"
            component="h2"
            className={cl("modal_title")}
          />
          <div
            dangerouslySetInnerHTML={{ __html: privacyPage.blurb1 }}
            className={cl("modal_blurb")}
          />
        </div>
      </Modal>
    </div>
  );
}
