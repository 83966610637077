import styles from "./DesktopNav.scss";
import { _classes } from "@scripts/utilities/helpers";
import React, { useState } from "react";
import { Text, Link } from "@scripts/widgets";
import { useAppState } from "@scripts/providers";

const cl = _classes(styles);

DesktopNav.propTypes = {
  children: PropTypes.children,
  items: PropTypes.array,
  toggleContactModal: PropTypes.func,
};

export default function DesktopNav({ items, children, toggleContactModal }) {
  const { childrenByPath, byTemplate } = useAppState();
  const [activeMenu, setActiveMenu] = useState(null);
  const officesPath = byTemplate("offices")[0]?.path;
  const prebuiltOfficesPath = byTemplate("prebuilt_offices")[0]?.path;
  // Children
  const renderChildren = (item, children) => {
    const visible = item.id === activeMenu;
    const activePath = window.location.pathname.match(item.path);
    return (
      <ul
        className={cl([
          "child_menu",
          visible && "visible",
          activePath && "active",
        ])}
      >
        {children.map((item) => (
          <li key={item.id} className={cl("child_item")}>
            <Link className={cl("link_element")} path={officesPath}>
              <Text
                text={"Full Floor Offices"}
                font="secondary"
                variant="body2"
                className={cl("text")}
              />
            </Link>
          </li>
        ))}
        <li key={item.id} className={cl("child_item")}>
          <Link className={cl("link_element")} path={prebuiltOfficesPath}>
            <Text
              text={"Pre-Built Offices"}
              font="secondary"
              variant="body2"
              className={cl("text")}
            />
          </Link>
        </li>
      </ul>
    );
  };

  const renderNavLinks = (_items, className, hasContactButton) => (
    <ul className={cl(["list", className])}>
      {_items?.map((item) => {
        const activePath = window.location.pathname.match(item.path);
        const children = childrenByPath(item.path)?.filter(
          (item) => !item.template.match(/map|incentives/)
        );

        return (
          <li
            key={item.id}
            className={cl(["item", activePath && "active"])}
            onMouseOver={() => setActiveMenu(item.id)}
            onMouseOut={() => setActiveMenu(null)}
            onFocus={() => setActiveMenu(item.id)}
            onBlur={() => setActiveMenu(null)}
          >
            {children && children.length ? (
              <>
                <Text
                  className={cl([
                    "link_text",
                    "link",
                    activePath && "active",
                    "has_children",
                  ])}
                  text={item.linktitle}
                  font="bold"
                  // className={cl("link_text")}
                  component="p"
                />
                {children && children.length
                  ? renderChildren(item, children)
                  : null}
              </>
            ) : (
              <Link
                path={item.path}
                // className={cl(["link", activePath && "active"])}
              >
                <Text
                  className={cl(["link_text", "link", activePath && "active"])}
                  text={item.linktitle}
                  font="bold"
                  // className={cl("link_text")}
                  component="p"
                />
                {children && children.length
                  ? renderChildren(item, children)
                  : null}
              </Link>
            )}
          </li>
        );
      })}
      {hasContactButton && (
        <li className={cl(["item"])}>
          <Link
            onClick={toggleContactModal}
            className={cl(["link", "contact_button"])}
          >
            <Text
              text={"Contact"}
              font="bold"
              className={cl("link_text")}
              component="p"
            />
          </Link>
        </li>
      )}
    </ul>
  );

  return (
    <nav className={cl("root")}>
      {renderNavLinks(items?.slice(0, 3), "left")}
      <div className={cl("center")}>{children}</div>
      {renderNavLinks(items?.slice(3), "right", true)}
    </nav>
  );
}
